const gender = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "all", label: "All Genders/Not Listed" },
];

const stage = [
  { value: "pre-treatment", label: "Pre-treatment" },
  { value: "post-treatment", label: "Post-treatment" },
  { value: "unsure", label: "Unsure" },
];

const type = [
  { value: "preservation", label: "Preservation" },
  { value: "building", label: "Building" },
];

const puberty = [
  { value: "have", label: "Have" },
  { value: "have-not", label: "Have Not" },
];

const services = [
  {
    value: "ovarianTissueCryopreservation",
    label: "Ovarian Tissue Cryopreservation",
    gender: "female",
    stage: "pre-treatment",
    type: "preservation",
    puberty: "",
    description:
      "A part of the ovary is gently removed, cut into thin pieces, and safely frozen to preserve fertility for the future.",
  },
  {
    value: "testicularTissueCryopreservation",
    label: "Testicular-Tissue Cryopreservation",
    gender: "male",
    stage: "pre-treatment",
    type: "preservation",
    puberty: "have not",
    description:
      "For adolescent males who cannot yet produce sperm, a small piece of testicular tissue is carefully frozen with the hope of creating sperm later, offering a chance for future fertility. ",
  },
  {
    value: "eggFreezing",
    label: "Egg Freezing",
    gender: "female",
    stage: "post-treatment",
    type: "preservation",
    puberty: "have",
    description:
      "Mature eggs are retrieved from the ovaries, frozen, and stored, giving women the option to plan for their future family when the time is right.",
  },
  {
    value: "embryoFreezing",
    label: "Embryo Freezing",
    gender: "female",
    stage: "post-treatment",
    type: "preservation",
    puberty: "have",
    description:
      "Eggs are collected, fertilized in a lab, and the embryos are frozen. This provides couples or individuals the opportunity to grow their family when they're ready.",
  },
  {
    value: "spermBanking",
    label: "Sperm Banking",
    gender: "male",
    stage: "",
    type: "preservation",
    puberty: "have",
    description:
      "Semen is collected, frozen, and stored for future family planning, offering peace of mind for men wanting to preserve their fertility.",
  },
  {
    value: "ovarianTissueStorage",
    label: "Long-Term Ovarian Tissue Storage",
    gender: "female",
    stage: "post-treatment",
    type: "preservation",
    puberty: "",
    description:
      "Ovarian tissue is securely stored for as long as needed, keeping fertility options open for the future.",
  },
  {
    value: "eggEmbryoStorage",
    label: "Long-Term Egg/Embryo Storage",
    gender: "female",
    stage: "post-treatment",
    type: "preservation",
    puberty: "have",
    description:
      "Eggs or embryos are safely stored long-term, providing the flexibility to start or expand your family when the time feels right.",
  },
  {
    value: "longTermSpermStorage",
    label: "Long-Term Sperm Storage",
    gender: "male",
    stage: "post-treatment",
    type: "preservation",
    puberty: "have",
    description:
      "Sperm is carefully stored for extended periods, giving individuals time and reassurance for future family-building decisions.",
  },
  {
    value: "fertilityTesting",
    label: "Fertility Assessment",
    gender: "",
    stage: "pre-treatment",
    type: "building",
    puberty: "have",
    description:
      "A personalized evaluation to understand your reproductive health and explore options for building your family.",
  },
  {
    value: "donorSperm",
    label: "Donor Sperm",
    gender: "",
    stage: "",
    type: "building",
    puberty: "have",
    description:
      "Donated sperm provides an option for individuals or couples to grow their family, especially when traditional conception isn't possible.",
  },
  {
    value: "surgicalSpermRetrieval",
    label: "Surgical Sperm Retrieval",
    gender: "male",
    stage: "",
    type: "preservation",
    puberty: "have",
    description:
      "A specialized procedure to retrieve sperm for men who need assistance with fertility treatments.",
  },
  {
    value: "donorEggs",
    label: "Donor Eggs",
    gender: "female",
    stage: "",
    type: "building",
    puberty: "have",
    description:
      "Eggs donated by another woman can help individuals or couples who cannot conceive with their own eggs, offering a path to pregnancy and parenthood.",
  },
  {
    value: "donorEmbryo",
    label: "Donor Embryo",
    gender: "female",
    stage: "",
    type: "building",
    puberty: "have",
    description:
      "Donor embryos from completed fertility treatments allow recipients to experience pregnancy and parenthood through embryo transfer.",
  },
  {
    value: "uterusTransplant",
    label: "Uterus Transplant",
    gender: "female",
    stage: "",
    type: "preservation",
    puberty: "have",
    description:
      "Women without a functioning uterus have the possibility of carrying a pregnancy through a uterus transplant, offering new hope for those who dream of becoming mothers.",
  },
  {
    value: "gestationalSurrogacy",
    label: "Gestational Surrogacy",
    gender: "",
    stage: "",
    type: "building",
    puberty: "have",
    description:
      "A compassionate surrogate carries a baby for intended parents who are unable to do so themselves, allowing them to experience the joy of parenthood. The surrogate is not genetically related to the baby.",
  },
  {
    value: "adoption",
    label: "Adoption Agencies",
    gender: "",
    stage: "",
    type: "bulding",
    puberty: "",
    description:
      "Supportive organizations that guide individuals and couples through the loving process of adoption, helping them grow their family.",
  },
  {
    value: "surrogacy",
    label: "Surrogacy Agencies",
    gender: "",
    stage: "",
    type: "bulding",
    puberty: "",
    description:
      "Agencies that connect intended parents with caring surrogates, offering support throughout the journey to parenthood.",
  },
  {
    value: "mentalHealthCounseling",
    label: "Mental Health and Counseling",
    gender: "",
    stage: "",
    type: "bulding",
    puberty: "",
    description:
      "Compassionate counseling supports individuals and couples through fertility challenges, promoting emotional resilience and well-being in their family-building journey.",
  },
];

export { gender, stage, type, puberty, services };
