import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../state/store.state';
import styled from 'styled-components';

import Header from '../header/header.container';
import Content from '../content/content.container';
import Footer from '../footer/footer.container';
import Result from '../result/result.container';
import { getData } from '../../api/data.api';
import { locateCurrentPosition } from '../../api/geolocation.api';

const loadScript = (url, callback) => {
    let script = document.createElement('script');
    script.type = 'text/javascript';

    if (script.readyState) {
        script.onreadystatechange = function () {
            if (
                script.readyState === 'loaded' ||
                script.readyState === 'complete'
            ) {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
};

const Main = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [showMap, setShowMap] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [globalState, dispatch] = useContext(Context);

    useEffect(() => {
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
            () => {}
        );

        locateCurrentPosition()
            .then((position) => {
                dispatch({
                    type: 'SET_GEOLOCATION',
                    payload: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    },
                });
            })
            .catch(() => {});

        getData().then((data) => {
            dispatch({ type: 'SET_DATA', payload: data });
            dispatch({ type: 'SET_CARDS', payload: data });
            dispatch({
                type: 'SET_SEARCHABLE_NAMES',
                payload: createSearchable(data),
            });

            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        isMobileWidth();
        window.addEventListener('resize', isMobileWidth, { passive: true });
        return () => window.removeEventListener('resize', isMobileWidth);
    }, []);

    const isMobileWidth = () => {
        const { innerWidth: width } = window;
        if (width <= 800) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    const createSearchable = (data) => {
        if (data) {
            // Create searchable names of clinic, doctors, practioners
            const searchable = [];
            const filterSearchableData = data.filter(
                (data) => data._approve === 'true'
            );
            for (const item of filterSearchableData) {
                searchable.push(item.clinic_practice_name);
                if (item.doctor) searchable.push(item.doctor);
            }
            const searchableNames = [...new Set(searchable)];
            const searchableNamesCleaned = searchableNames.filter(
                (item) => item !== undefined
            );
            return searchableNamesCleaned.sort();
        }
    };

    return (
        <React.Fragment>
            {showMap ? (
                <MapContainer>
                    <Header isMap={true} />
                    <Result back={() => setShowMap(false)} />
                </MapContainer>
            ) : (
                <MainContainer>
                    <Header />
                    <Content
                        goMap={() => setShowMap(true)}
                        isLoading={isLoading}
                        isMobileWidth={isMobileWidth}
                    />
                    <Footer />
                </MainContainer>
            )}
        </React.Fragment>
    );
};

export default Main;

const MainContainer = styled.main`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    height: 100%;
`;

const MapContainer = styled.main`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
`;
