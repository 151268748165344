import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import globalStyles from '../../assets/style/global.style';
import { Drawer, Collapse, Button, Pagination } from 'antd';
import { calcCoordinateDistance } from '../../util/map.util';

import ScoutLogo from '../../assets/img/fs_logo_icon.png';
import Text from '../../components/text/text.component';
import Icon from '../../components/icon/icon.component';
import PanelHeader from './panel-header.container';
import MapModal from './map-modal.container';
import { servicesMapper } from '../../util/services.util';

const { Panel } = Collapse;

const MapSearch = (props) => {

  const {
    back,
    globalState,
    center,
    cards,
    activeMarkerIndex,
    userCenter,
    setAccordionIndex,
  } = props;

  const [visible, setVisible] = useState(true);
  const [formattedCards, setformattedCards] = useState([]);
  const [paginatedCards, setPaginatedCards] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [cardEmail, setCardEmail] = useState(null);
  const [name, setName] = useState(null);
  const [type, setType] = useState(null);
  const [activeAccordionKey, setActiveAccordionKey] = useState('0');
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setUpCards();
  }, [userCenter, cards]);

  useEffect(() => {
    if (formattedCards.length) onAccordionChange('0', false);
  }, [formattedCards]);

  useEffect(() => {
    if (activeMarkerIndex !== null) {
      const page = activeMarkerIndex < 10 ? 1 : Math.floor(activeMarkerIndex / 10) + 1;
      const accordionKey = activeMarkerIndex % 10;
      const start = (page - 1) * 10;

      const paginatedCards = paginateCards(formattedCards, start);
      setPaginatedCards(paginatedCards);

      setCurrentPage(page);
      setActiveAccordionKey(`${accordionKey}`);
    }
  }, [activeMarkerIndex])

  const setUpCards = () => {
    const localFormattedCards = sortCardsByDistance();
    setformattedCards(localFormattedCards);
    const paginatedCards = paginateCards(localFormattedCards, 0);
    setPaginatedCards(paginatedCards);
  }

  const sortCardsByDistance = () => {
    let cardsSorted = cards.map(card => {
      let dist = (!card.latitude) ? 999999 : calcCoordinateDistance(center.lat, center.lng, card.latitude, card.longitude);
      return ({ ...card, distance: dist })
    })
    cardsSorted = cardsSorted.sort((a, b) => (a.distance > b.distance) ? 1 : -1);
    return cardsSorted;
  }

  const paginateCards = (cards, start = 0) => {
    const end = start + 10;
    const paginatedCards = cards.slice(start, end);
    return paginatedCards;
  }

  const onModalButtonClick = () => {
    setModalVisible(!modalVisible);
  }

  const onAccordionChange = (index, isUser) => {

    setActiveAccordionKey(`${index}`);

    // Find Card
    const calcIndex = ((currentPage - 1) * 10) + parseInt(index);

    // Set State
    const currentCard = formattedCards[calcIndex];
    if (currentCard) {
      if (currentCard.clinic_practice_name !== undefined) {
        setName(`${currentCard.clinic_practice_name} - ${currentCard.city}`)
      }
      if (currentCard.email_for_fertility_scout_contact !== undefined) {
        setCardEmail(currentCard.email_for_fertility_scout_contact);
      }
      if (currentCard.type !== undefined) {
        setType(currentCard.type);
      }
    }

    // Open marker tooltip on accordion change
    setAccordionIndex(calcIndex);
  }

  const onPageChange = (page) => {
    setActiveAccordionKey(null);
    const start = (page - 1) * 10;
    const paginatedCards = paginateCards(formattedCards, start);
    setPaginatedCards(paginatedCards);
    setCurrentPage(page);
  }

  const Toggle = styled.div`
    position: absolute;
    top: 10px;
    padding: 10px;
    background-color: white;
    left: ${visible ? '800px' : '0'};
    z-index: 1;
    opacity: .9;
    cursor: pointer;
  `;

  const cleanupUrl = (url) => {
    if (url.includes('https://') || url.includes('http://')) {
      return url;
    } else {
      return `https://${url}`;
    }
  }
 
  return (
    <React.Fragment>
    {globalState.data.length && (
      <React.Fragment>
        <Toggle onClick={() => setVisible(!visible)}>
          <Icon type={`${visible ? 'leftchevron' : 'rightchevron'}`}/>
        </Toggle>
        <Drawer
          visible={visible}
          placement={'left'}
          closable={false}
          mask={false}
          width={'800px'}
          style={{
            opacity: '.9',
            overflow: 'hidden',
          }}
          bodyStyle={{
            padding: '5px',
            opacity: '1',
            overflow: 'hidden',
          }}
        >
          <MapSearchContainer>
            <Header>
              <LogoContainer>
                <Logo><img src={ScoutLogo} alt='logo' style={{ maxHeight: 'inherit', maxWidth: 'inherit' }} /></Logo>
                <TextContainer>
                  <Text fontWeight='700' fontSize='1.1rem'>Fertility Scout</Text>
                  <Text>Alliance for Fertility Preservation</Text>
                </TextContainer>
              </LogoContainer>
              <ActionContainer>
                <Text 
                  cursor='pointer'
                  textDecoration='underline' 
                  hoverColor={globalStyles.color.afpGreen} 
                  fontSize='1rem'
                  onClick={back}
                  color={globalStyles.color.afpBlue}
                > 
                  Back to Selection Tool
                </Text>
              </ActionContainer>
            </Header>
            <CardContainer>
              <Collapse 
                accordion 
                expandIcon={null} 
                expandIconPosition={'right'} 
                defaultActiveKey={['0']}
                activeKey={[activeAccordionKey]}
                onChange={e => onAccordionChange(e, true)}
              >
                {paginatedCards.map((card, index) =>
                  <Panel header={<PanelHeader card={card} />} key={index}>
                    <PanelContainer>
                      <PanelLeft>
                        {card._approve_for_email === 'true' && card.area_of_interest_expertise_or_research && (
                          <React.Fragment>
                            <b>Description:</b>
                            <p>{card.area_of_interest_expertise_or_research}</p>
                          </React.Fragment>
                        )}

                        {card._approve_for_email === 'true' && card.organizational_affiliation && (
                          <React.Fragment>
                            <b>Organizational Affiliation:</b>
                            <p>{card.organizational_affiliation}</p>
                          </React.Fragment>
                        )}

                        <b>Address:</b>
                        <p>
                          {card.street_address} {card.suite_floor} <br></br>
                          {card.city}, {card.us_states} {card.zip}
                        </p>

                        {card.practice_url && (
                          <React.Fragment>
                            <b>Website:</b>
                            <p><Link target='_blank' href={cleanupUrl(card.practice_url)}>{card.practice_url}</Link></p>
                          </React.Fragment>
                        )}

                        {card.doctor && (
                          <React.Fragment>
                            <b>Physician:</b>
                            <p>
                              {card.doctor} {card.title ? `, ${card.title}` : null}
                            </p>
                          </React.Fragment>
                        )}

                        {card.phone_number && (
                          <React.Fragment>
                            <b>Phone Number:</b>
                            <p>{card.phone_number}</p>
                          </React.Fragment>
                        )}
                      </PanelLeft>

                      <PanelRight>
                        {card.services?.filter(service => service !== '0' && service !== undefined)?.length > 0 && (
                          <React.Fragment>
                            <b>Services Provided:</b>
                            {card.services
                              .filter(service => service !== '0' && service !== undefined)
                              .map((service, index) => {
                                const serviceLabel = servicesMapper(service);
                                return serviceLabel ? <li key={index}><Dot>&#183;</Dot>{serviceLabel}</li> : null;
                              })
                            }
                          </React.Fragment>
                        )}
                      </PanelRight>

                      {(card._approve_for_email === 'true') && (
                        <Button
                          type={'primary'}
                          shape={'round'}
                          onClick={() => setModalVisible(true)}
                        >
                          {card.type === 'clinic' ? 'Request Consultation' : 'Contact Directly'}
                        </Button>
                      )}
                    </PanelContainer>
                  </Panel>
                )}
              </Collapse>
              <PaginationContainer>
                <Pagination
                  current={currentPage}
                  total={cards.length}
                  showSizeChanger={false}
                  onChange={onPageChange}
                />
              </PaginationContainer>
            </CardContainer>
          </MapSearchContainer>
        </Drawer>
      </React.Fragment>
    )}
      <MapModal 
        onModalButtonClick={onModalButtonClick}
        cardEmail={cardEmail}
        name={name}
        type={type}
        modalVisible={modalVisible}
      />
    </React.Fragment>
  )
}

const MapSearchContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const LogoContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${globalStyles.color.afpGrey};
`;

const Header = styled.div``;

const Logo = styled.div`
  display: block; 
  max-width: 120px;
  max-height: 100px;
  width: auto;
  height: auto;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px 0;
  border-bottom: 1px solid ${globalStyles.color.afpGrey};
`;

const CardContainer = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 125px);
`;

const Link = styled.a`
  text-decoration: underline;
  color: black;

  &:focus, &:hover {
    text-decoration: underline;
    color: ${globalStyles.color.afpDarkBlue};
  }
`;

const PanelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PanelLeft = styled.div`
  min-width: 40%;
  width: auto;
`;

const PanelRight = styled.div`
  width: 60%;
`;

const Dot = styled.span`
  padding-left: 5px;
  font-weight: 800;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding-bottom: 20px;
`;

export default MapSearch;